<template>
  <div class="content-option-buttons">
    <div @click="onReturn" class="content-header">
      <p class="txt-title poppins mb-0">
        <v-icon color="#283c4d"> mdi-arrow-left-thick </v-icon>
        Detalle de tarjetas SIM
      </p>
    </div>
    <v-spacer />
    <div class="content-buttons global-btn-mobile">
      <v-btn v-show="getPermissionsActionsGlobal()" elevation="0" :loading="bLoadingActive" @click="changeStatus(true)"
        v-if="oFixedInfo.sStatus === 'Desactivada' || oFixedInfo.sStatus === 'Prueba' " class="button-secondary global-btn-mobile">Activar</v-btn>
      <v-btn v-show="getPermissionsActionsGlobal()" elevation="0" :loading="bLoadingDesactived" @click="changeStatus(false)"
        v-if="oFixedInfo.sStatus === 'Activa' && getPermissionsActionsGlobal()" class="button-secondary global-btn-mobile">Desactivar</v-btn>
      <v-btn v-show="getPermissionsActionsGlobal()" elevation="0" @click="setDialogResetSim" class="button-secondary global-btn-mobile ml-2">Reiniciar tarjeta
      </v-btn>
      <v-btn v-show="getPermissionsActionsGlobal()" elevation="0" :loading="bLoadingTest" @click="launchTest" class="button-secondary global-btn-mobile ml-2">
        Lanzar prueba</v-btn>
    </div>
    <v-dialog v-model="bDialogResetSim" persistent @keydown.esc="setDialogResetSim" :width="screenWidth">
      <div class="content-card-dialog">
        <v-btn class="content-card-button-close" @click="setDialogResetSim" icon>
          <v-icon color="#000" size="16px"> mdi-close </v-icon>
        </v-btn>
        <p class="content-card-txt-title poppins mb-0">
          Reinicio de tarjeta SIM
        </p>
        <p class="content-card-txt-description poppins mt-2">
          Selecciona la red(es) que deseas reiniciar
          <!-- ¿Esta seguro de realizar el reset de la tarjeta SIM? <br />
          Presione el boton de confirmación para realizar esta acción. -->
        </p>
        <div class="content-checkboxes-4g-3g2g">
          <div>
            <v-checkbox v-model="chb4g" label="4G" hide-details color="#2759a2" class="mb-1 mt-0" v-if="bLteEnabled">
              <template v-slot:label>
                <p class="txt-label-chb-4g-3g2g mb-0">4G</p>
              </template>
            </v-checkbox>
          </div>
          <v-spacer v-if="bLteEnabled"></v-spacer>
          <v-checkbox v-model="chb3g2g" label="3G o 2G" hide-details color="#2759a2" class="mb-1 mt-0 ml-3">
            <template v-slot:label>
              <p class="txt-label-chb-4g-3g2g mb-0">3G o 2G</p>
            </template>
          </v-checkbox>
        </div>
        <div class="content-buttons">
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col cols="12" sm="5">
                <div class="
                    display-flex
                    align-items-center
                    justify-content-flex-start
                  ">
                  <v-btn @click="setDialogResetSim" class="button-secondary poppins" elevation="0" width="100%">
                    Cancelar
                  </v-btn>
                </div>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="5">
                <div class="
                    display-flex
                    align-items-center
                    justify-content-flex-end
                  ">
                  <v-btn @click="resetSim" :loading="bLoading" :disabled="valForm()" class="button-primary poppins"
                    elevation="0" width="100%">
                    Reiniciar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="bDialogLaunchTest" persistent @keydown.esc="setDialogLaunchTest"
      :width="screenWidthDialogLaunchTest">
      <div class="content-card-dialog">
        <v-btn class="content-card-button-close" @click="setDialogLaunchTest" icon>
          <v-icon color="#000" size="16px"> mdi-close </v-icon>
        </v-btn>
        <p class="content-card-txt-title poppins mb-5 mt-5">
          Respuesta de la prueba
        </p>
        <div class="content-card-body-test">
          <!-- #region Estado GSM -->
          <p class="txt-label-test">Estado GSM:</p>
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oGPRS.result.includes('UP')
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">
              {{ bDialogLaunchTest ? itemTest.oGSM.result : "" }}
            </p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oGSM.result.includes('UP')
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oGSM.result.includes('UP')
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
          <!-- #endregion Estado GSM -->

          <!-- #region Estado GPRS -->
          <p class="txt-label-test">Estado GPRS:</p>
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oGPRS.result.includes('UP')
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">
              {{ bDialogLaunchTest ? itemTest.oGPRS.result : "" }}
            </p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oGPRS.result.includes('UP')
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oGPRS.result.includes('UP')
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
          <!-- #endregion Estado GPRS -->

          <!-- #region Estado administrativo -->
          <p class="txt-label-test mb-5 mt-5">Estado administrativo:</p>
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oAdministrative.globalStatus
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">Estado global</p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oAdministrative.globalStatus
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oAdministrative.globalStatus
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
          <!-- #endregion Estado administrativo -->
          
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oAdministrative.voiceStatus
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">Voz</p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oAdministrative.voiceStatus
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oAdministrative.voiceStatus
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oAdministrative.dataStatus
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">Datos</p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oAdministrative.dataStatus
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oAdministrative.dataStatus
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
          <div class="content-value-test" :style="{
            'background-color': bDialogLaunchTest
              ? itemTest.oAdministrative.smsStatus
                ? '#E5F8EC'
                : '#FFE5E8'
              : '',
          }">
            <p class="text-value-test">SMS</p>
            <v-spacer />
            <v-icon :color="
              bDialogLaunchTest
                ? itemTest.oAdministrative.smsStatus
                  ? 'green'
                  : 'red'
                : ''
            ">
              {{
                  bDialogLaunchTest
                    ? itemTest.oAdministrative.smsStatus
                      ? "mdi-check"
                      : "mdi-close"
                    : ""
              }}
            </v-icon>
          </div>
        </div>

        <div class="content-buttons ">
          <div class="content-btn-close">
            <v-btn @click="setDialogLaunchTest" class="button-primary poppins" elevation="0" width="100%">
              Cerrar
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <confirm-change-status-component :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus" />
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutDetailSimCardsHeader",
  props: {
    bLteEnabled: Boolean,
    oFixedInfo: Object,
  },
  components: {
    ConfirmChangeStatusComponent
  },
  data() {
    return {
      bDialogResetSim: false,
      bLoading: false,
      bLoadingTest: false,
      bLoadingActive: false,
      bLoadingDesactived: false,
      screenWidth: 0,
      chb4g: false,
      chb3g2g: false,
      bDialogLaunchTest: false,
      screenWidthDialogLaunchTest: 0,
      itemTest: {},
      oFixedInfoEdit: {},
      oChangeStatusOptions: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
  },
  methods: {
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URL_KITE}/${this.$route.params.id}/status`,
        payload: { sLifeCycleStatus: (bAction ? "ACTIVE" : "DEACTIVATED") },
        bInputs: true,
        title:
          bAction == true
            ? "Activación de tarjeta SIM"
            : "Desactivación de tarjeta SIM",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción activara la tarjeta SIM.</br>¿Desea continuar?"
            : "La siguiente acción desactivara la tarjeta SIM.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Activar" : "Desactivar",
      };
    },
    handleResize() {
      if (window.innerWidth > 600) {
        this.screenWidth = 420 + "px";
        this.screenWidthDialogLaunchTest = 420 + "px";
      } else {
        this.screenWidth = 100 + "%";
        this.screenWidthDialogLaunchTest = 100 + "%";
      }
    },
    onReturn: function () {
      this.$router.push({ name: "sim-cards" });
    },
    setDialogResetSim() {
      this.bDialogResetSim = !this.bDialogResetSim;
      this.bLoading = false;
      this.chb4g = false;
      this.chb3g2g = false;
    },
    setDialogLaunchTest() {
      this.bDialogLaunchTest = !this.bDialogLaunchTest;
    },
    resetSim() {
      this.bLoading = true;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          bNetwork4g: this.chb4g,
          bNetwork2g3g: this.chb3g2g,
        };

      DB.patch(
        `${URL_KITE}/${this.$route.params.id}/reset`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoading = false;
          this.setDialogResetSim();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.bLoading = false;
        });
    },
    launchTest() {
      this.bLoadingTest = true;
      DB.get(`${URL_KITE}/${this.$route.params.id}/diagnostic`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          // this.mixSuccess(response.data.message);
          this.itemTest = response.data.results;
          this.setDialogLaunchTest();
          this.bLoadingTest = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.bLoadingTest = false;
        });
    },
    valForm() {
      return this.chb4g !== true && this.chb3g2g !== true;
    },
    setActive() {
      this.changeStatus()
      // this.bLoadingActive = true;
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${this.$store.state.sToken}`,
      //   },
      // },
      //   payload = {
      //     sLifeCycleStatus: "ACTIVE",
      //   };

      // DB.patch(`${URL_KITE}/${this.$route.params.id}/status`, payload, config)
      //   .then((response) => {
      //     this.bLoadingActive = false;

      //     this.mixSuccess(response.data.message);
      //     this.$store.commit("refresher", true);
      //   })
      //   .catch((error) => {
      //     this.bLoadingActive = false;
      //     this.mixError(error.response.data.message);
      //   });
    },
    setDesactived() {
      this.bLoadingDesactived = true;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          sLifeCycleStatus: "DEACTIVATED",
        };

      DB.patch(`${URL_KITE}/${this.$route.params.id}/status`, payload, config)
        .then((response) => {
          this.bLoadingDesactived = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoadingDesactived = false;
          this.mixError(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.content-return:hover {
  opacity: 0.8;
  cursor: pointer;
  width: fit-content;
}

.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-name {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  color: #2759a2;
}

.content-checkboxes-4g-3g2g {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.txt-label-chb-4g-3g2g {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

.txt-label-test {
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 10px;
}

.text-value-test {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
}

.content-value-test {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.content-buttons {
  display: flex;
  justify-content: center !important;
  text-align: center;
  text-align: -webkit-center !important;
}

.content-btn-close {
  width: 200px;
}

.content-option-buttons {
  display: flex !important;
  align-items: center;
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-option-buttons {
    display: block !important;
    align-items: center;
    width: 100%;
  }

  .content-buttons {
    display: flex;
    justify-content: center !important;
    text-align: -webkit-center !important;
  }

  .global-btn-mobile {
    display: block;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-option-buttons {
    display: block !important;
    align-items: center;
    width: 100%;
  }

  .content-buttons {
    /* display: block; */
    justify-content: center !important;
    text-align-last: center !important;
    text-align: -webkit-center !important;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>