<template>
  <div>
    <div class="content-title-section mb-2">Top 30 de clientes por excederse</div>
    <!-- :style="{'height': iHeight + 'px','min-height': iHeight + 'px' }" -->
    <div
      class="content-dashboard content-sim-cards-top"
      :style="{ height: iHeight, 'min-height': iHeight }"
    >
      <v-row>
        <v-col cols="12" v-for="(item, i) in aSimCardsTop" :key="i">
          <div class="content-dashboard-card">
            <div class="content-dashboard-left">
              <span class="txt-result-number-dashboard-card">
                {{ i + 1 }}
              </span>
            </div>
            <div class="divider-dashboard-card" />
            <div class="content-dashboard-right">
              <div class="content-title-result-dashboard font-bold">
                <span class="txt-result-dashboard-card">{{ item.sName }}</span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card">{{ item.sCustomPlanName }}</span>
              </div>
              <!-- <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card">{{ item.sPlan }}</span>
              </div> -->
              <div class="content-title-result-dashboard">
                <v-progress-linear
                  height="15"
                  rounded
                  :color="getColorGlobal(item.oConsumption, item.oDataPool)"
                  :value="item.dProcessBar"
                >
                  <template v-slot:default="{ value }">
                    <span class="txt-value-progress-linear-dashboard-card">{{
                      item.sCurrentAndLimit
                    }}</span>
                  </template>
                </v-progress-linear>
              </div>
            </div>
          </div>
        </v-col>
        <div
          class="content-dashboard d-flex justify-center txt-empty-global"
          v-if="aSimCardsTop.length === 0"
        >
          No hay información
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aSimCardsTop: Array,
  },
  data() {
    return {
      // aSimCardsTop: [
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "2",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "3",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      //     {
      //         iIndex: "1",
      //         sID: "233534534523432423",
      //         sName: "Taxis del Sur",
      //         sPlan: "10MB NACIONAL",
      //         dProcessBar: 20
      //     },
      // ],
      iHeight: 0,
      bChangeSize: false,
    };
  },
  updated() {
    this.onResize();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      if (window.innerWidth > 959.99) {
        this.iHeight = window.innerHeight - 190 + "px";
      } else {
        this.iHeight = "auto";
      }
    },
  },
  computed: {
    iContentPromotionsDashboard() {
      return this.$store.state.iContentPromotionsDashboard;
    },
    iContentPlansDashboard() {
      return this.$store.state.iContentPlansDashboard;
    },
    bMenu() {
      return this.$store.state.bMenu;
    },
  },
  watch: {
    bMenu() {
      this.onResize();
    },
  },
};
</script>
<style>
.content-sim-cards-top {
  overflow: auto;
  /* height: 795px; */
}

.w-1060px {
  /* height: 1020px !important; */
}

.txt-value-progress-linear-dashboard-card {
  font-size: 11px !important;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-sim-cards-top {
    overflow: auto;
    height: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-sim-cards-top {
    overflow: auto;
    height: 100%;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
