import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import VueCurrencyInput from "vue-currency-input";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import lodash from "lodash";
import moment from "moment";
import { mixs } from "@/mixins";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/modules";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
Vue.use(DatePicker)

Vue.use(VueMapbox, { mapboxgl: Mapbox });

Vue.config.productionTip = false;

// import of mixins for the alert call
Vue.mixin(mixs);

// use of alerts throughout the project
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0",
    libraries: "places",
  },
});
// import currency
// Vue.use(VueCurrencyInput, {
//   locale: "es",
//   currency: "MXN",
//   valueAsInteger: false,
//   distractionFree: false,
//   precision: 2,
//   autoDecimalMode: true,
//   allowNegative: false,
// });

// import global apex charts
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// import axios
window.DB = axios;

// import lodas
window.lodash = lodash;

// import moment
window.MOMENT = moment;

// initailize URI for global use
var bProduction = false;
window.URI = "https://api.lineasmoviles.com/alfa/api/v1"; //'https://api.lineasmoviles.com/beta/api/v1';
window.URL_KITE = "https://jievyrijje.execute-api.us-east-1.amazonaws.com/alfa/global"; //alfa  or  alfa
window.URL_KITE_LOCAL = "https://jievyrijje.execute-api.us-east-1.amazonaws.com/alfa/local"; //qa  or  dev

window.KEY_KITE =
  "145dc49a7dce696e8a4197f06f46fab5ab8b2cbadbd25b947bb46a53c0faf6f4c7a9259ca5856838934d16309b6d730f667bcd4c506bd573773282af46bebfbf7500973963fdeea90eacf8f4141109ab3bdfdd7086715d3f4211bb455051bda0cea80908e0a838cec76d605b344ce91556562e59f28aa16bffbf2ad4a7fcb34cf91519a92ed02511594ffc83ea5ee615815d2c74af53c09ad2f604d2e1419570b6e3b2cca6c0e2c15302e9a83b1762d46fe92885f6ff0e1f4142c8388166a4c1e2e74135b9fb2090dc46179a74128527fe8e24c4cb7a1214753e4e8428f69ab8b16e654e9894a3c093435b14c22af0400069bfe2a145735a81052320c68c69f9";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
