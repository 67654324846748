<template>
  <v-dialog v-model="activeDialogEdit" persistent :width="screenWidth + '%'">
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="closeDialog" icon>
        <v-icon color="#000" size="16px"> mdi-close </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins mb-8">Modificación de plan</p>

      <div>
        <v-container class="pl-0 pr-0 ml-1" fluid>
          <v-row>
            <!-- <v-col cols="6">
              <v-text-field
                v-model="sNamePlan"
                type="text"
                label="Nombre base del plan"
                placeholder="Nombre base del plan"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                persistent-placeholder
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="sDescriptionPlan"
                type="text"
                label="Descripción del plan base"
                placeholder="Descripción del plan base"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                persistent-placeholder
                disabled
              ></v-text-field>
            </v-col> -->
            <v-col cols="6">
              <!-- :disabled="!getPermissionsActionsGlobal()" -->
              <v-text-field
                v-model="sNamePlanEdit"
                type="text"
                label="Nombre personalizado del plan"
                :disabled="true"
                :readonly="true"
                placeholder="Nombre personalizado del plan"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                persistent-placeholder
              >
                <template slot="label">
                  <span
                    >Nombre personalizado del plan
                    <span class="color-red">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :disabled="!getPermissionsActionsGlobal()"
                v-model="sDescriptionPlanEdit"
                type="text"
                label="Descripción del plan personalizado"
                placeholder="Descripción del plan personalizado"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                persistent-placeholder
              >
                <template slot="label">
                  <span
                    >Descripción del plan personalizado
                    <span class="color-red">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div>
        <p class="txt-title poppins mb-0">Caracteristicas del plan</p>
        <v-container class="pl-0 pr-0 ml-1" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pa-1">
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="display-flex align-items-center height-100"
                >
                  <p class="txt-description w-200 poppins mb-0">
                    Precio unitario <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioUnitario"
                    :modelValue="precioUnitario"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description poppins w-200 mb-0">
                    Precio por día <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioDia"
                    :modelValue="precioDia"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description poppins w-200 mb-0">
                    Precio por MB excedido <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioMbExcedido"
                    :modelValue="precioMbExcedido"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description poppins w-200 mb-0">
                    Límite de datos mensuales <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoLimiteDatosMenusales"
                    :modelValue="dMonthlyDataLimit"
                    sSuffix="MB"
                    sPrefix=""
                  />
                </div>
              </v-col>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pa-1">
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description poppins w-200 mb-0">
                    Precio por SMS <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioPorSMS"
                    :modelValue="dPricePerSMS"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description w-200 poppins mb-0">
                    Precio por llamada (entrante)
                    <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioLlamadaEntrante"
                    :modelValue="precioLlamadaEntrante"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description w-200 poppins mb-0">
                    Precio por llamada (saliente)
                    <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoPrecioLlamadaSaliente"
                    :modelValue="precioLlamadaSaliente"
                    sPrefix=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description w-200 poppins mb-0">
                    Límite de SMS mensuales <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoMonthlySMSLimit"
                    :modelValue="dMonthlySMSLimit"
                    sPrefix=""
                    sSuffix="SMS"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  :class="bMobile ? 'd-block' : 'display-flex'"
                  class="align-items-center height-100"
                >
                  <p class="txt-description w-200 poppins mb-0">
                    Límite de llamadas mensuales
                    <span class="color-red">*</span>
                  </p>
                  <input-currency-global
                    :bDisabled="!getPermissionsActionsGlobal()"
                    :flagModal="activeDialogEdit"
                    labelGlobal=""
                    classDynamic="global-inputs poppins"
                    :bOutlined="false"
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoMonthlyVoiceLimit"
                    :modelValue="dMonthlyVoiceLimit"
                    sPrefix=""
                    sSuffix="MIN"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div>
        <div class="content-buttons">
          <v-btn
            @click="closeDialog"
            class="button-secondary poppins"
            elevation="0"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <!-- :disabled="!validationForm" -->

          <v-btn
            v-if="getPermissionsActionsGlobal()"
            @click="send"
            :loading="bLoading"
            class="button-primary poppins"
            elevation="0"
          >
            Modificar
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutDetailClientEditPlan",
  props: {
    activeDialogEdit: {
      type: Boolean,
    },
    objDialogEdit: {
      type: Object,
    },
  },
  data() {
    return {
      screenWidth: 0,
      sEnterpriseId: null,
      sCustomPlanId: null,
      sPlanId: null,
      sNamePlan: "",
      sDescriptionPlan: "",
      sNamePlanEdit: "",
      sDescriptionPlanEdit: "",
      iPrecioUnitario: 0,
      precioUnitario: 0,
      precioDia: 0,
      dMonthlyDataLimit: 0,
      precioMbExcedido: 0,
      dPricePerSMS: 0,
      precioLlamadaEntrante: 0,
      precioLlamadaSaliente: 0,
      dMonthlySMSLimit: 0,
      dMonthlyVoiceLimit: 0,
      bLoading: false,
      bLoadingItem: false,
      bMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getItem() {
      this.bLoadingItem = true;

      DB.get(
        `${URI}/enterprises/${this.$route.params.id}/custom-plans/${this.objDialogEdit.sCustomPlanId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.bLoadingItem = false;
          this.setFillData(response.data.results);
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingItem = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setFillData(oItem) {
      this.sPlanId = oItem.sPlanId;
      this.sNamePlan = oItem.sBasePlanName;
      this.sNamePlanEdit = oItem.sName;
      this.sDescriptionPlan = oItem.sBasePlanDescription;
      this.sDescriptionPlanEdit = oItem.sDescription;

      this.precioUnitario = parseFloat(oItem.dUnitPrice);
      this.precioDia = parseFloat(oItem.dPricePerDay);
      this.precioMbExcedido = parseFloat(oItem.dPricePerMegabyteExceeded);
      this.dMonthlyDataLimit = parseFloat(oItem.dMonthlyDataLimit);
      this.dPricePerSMS = parseFloat(oItem.dPricePerSMS);
      this.precioLlamadaEntrante = parseFloat(oItem.dPricePerIncomingCall);
      this.precioLlamadaSaliente = parseFloat(oItem.dPricePerOutgoingCall);
      this.dMonthlySMSLimit = parseFloat(oItem.dMonthlySMSLimit);
      this.dMonthlyVoiceLimit = parseFloat(oItem.dMonthlyVoiceLimit);
    },
    send: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                dUnitPrice: this.precioUnitario ? this.precioUnitario : 0,
                dPricePerDay: this.precioDia ? this.precioDia : 0,
                dPricePerMegabyteExceeded: this.precioMbExcedido
                  ? this.precioMbExcedido
                  : 0,
                dPricePerSMS: this.dPricePerSMS ? this.dPricePerSMS : 0,
                dPricePerIncomingCall: this.precioLlamadaEntrante
                  ? this.precioLlamadaEntrante
                  : 0,
                dPricePerOutgoingCall: this.precioLlamadaSaliente
                  ? this.precioLlamadaSaliente
                  : 0,
               // sPlanId: this.sPlanId,
               // sName: this.sNamePlanEdit,
                sDescription: this.sDescriptionPlanEdit,
                dMonthlyDataLimit: this.dMonthlyDataLimit
                  ? this.dMonthlyDataLimit
                  : 0,
                dMonthlySMSLimit: this.dMonthlySMSLimit
                  ? this.dMonthlySMSLimit
                  : 0,
                dMonthlyVoiceLimit: this.dMonthlyVoiceLimit
                  ? this.dMonthlyVoiceLimit
                  : 0,
              };

            DB.put(
              `${URI}/enterprises/${this.$route.params.id}/custom-plans/${this.objDialogEdit.sCustomPlanId}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.closeDialog();
                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;

                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.closeDialog();

            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.closeDialog();

          this.$store.commit("refresher", true);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth > 599.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 80;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 60;
      }
      // if (window.innerWidth > 960) {
      //   this.screenWidth = "430px";
      // } else {
      //   this.screenWidth = "100%";
      // }
    },
    closeDialog: function () {
      this.$emit("setAsignPlanEdit", {
        activeDialogEdit: false,
        objDialogEdit: {},
      });
      this.sNamePlan = "";
      this.sDescriptionPlan = "";
      this.precioUnitario = 0;
      this.precioDia = 0;
      this.precioMbExcedido = 0;
      this.dPricePerSMS = 0;
      this.precioLlamadaEntrante = 0;
      this.precioLlamadaSaliente = 0;
      this.dMonthlyDataLimit = 0;
      this.dMonthlySMSLimit = 0;
      this.dMonthlyVoiceLimit = 0;
    },
    changeMontoPrecioUnitario(value) {
      if (value !== "") {
        this.precioUnitario = parseFloat(value);
      } else {
        this.precioUnitario = 0;
      }
    },
    changeMontoPrecioDia(value) {
      if (value !== "") {
        this.precioDia = parseFloat(value);
      } else {
        this.precioDia = 0;
      }
    },
    changeMontoPrecioMbExcedido(value) {
      if (value !== "") {
        this.precioMbExcedido = parseFloat(value);
      } else {
        this.precioMbExcedido = 0;
      }
    },
    changeMontoLimiteDatosMenusales(value) {
      if (value !== "") {
        this.dMonthlyDataLimit = parseFloat(value);
      } else {
        this.dMonthlyDataLimit = 0;
      }
    },
    changeMontoPrecioPorSMS(value) {
      if (value !== "") {
        this.dPricePerSMS = parseFloat(value);
      } else {
        this.dPricePerSMS = 0;
      }
    },
    changeMontoPrecioLlamadaEntrante(value) {
      if (value !== "") {
        this.precioLlamadaEntrante = parseFloat(value);
      } else {
        this.precioLlamadaEntrante = 0;
      }
    },
    changeMontoPrecioLlamadaSaliente(value) {
      if (value !== "") {
        this.precioLlamadaSaliente = parseFloat(value);
      } else {
        this.precioLlamadaSaliente = 0;
      }
    },
    changeMontoMonthlySMSLimit(value) {
      if (value !== "") {
        this.dMonthlySMSLimit = parseFloat(value);
      } else {
        this.dMonthlySMSLimit = 0;
      }
    },
    changeMontoMonthlyVoiceLimit(value) {
      if (value !== "") {
        this.dMonthlyVoiceLimit = parseFloat(value);
      } else {
        this.dMonthlyVoiceLimit = 0;
      }
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.sNamePlanEdit !== "" && this.sDescriptionPlanEdit !== ""
        // &&
        // this.precioUnitario > 0 &&
        // this.precioDia > 0 &&
        // this.precioMbExcedido > 0 &&
        // this.dPricePerSMS > 0 &&
        // this.precioLlamadaEntrante > 0 &&
        // this.precioLlamadaSaliente > 0 &&
        // this.dMonthlySMSLimit > 0 &&
        // this.dMonthlyVoiceLimit > 0
      );
    },
  },
  watch: {
    objDialogEdit: function () {
      if (this.activeDialogEdit) {
        let item = this.objDialogEdit;
        console.log(item)
        this.sEnterpriseId = item.sEnterpriseId;
        this.sCustomPlanId = item.sCustomPlanId;
        this.getItem();
        // this.sNamePlan = item.sBasePlanName;
        // this.sNamePlanEdit = item.sName;
        // this.sDescriptionPlan = item.sBasePlanDescription;
        // this.sDescriptionPlanEdit = item.sDescription;

        // this.precioUnitario = parseFloat(item.dUnitPrice);
        // this.precioDia = parseFloat(item.dPricePerDay);
        // this.precioMbExcedido = parseFloat(item.dPricePerMegabyteExceeded);
        // this.dMonthlyDataLimit = parseFloat(item.dMonthlyDataLimit);
        // this.dPricePerSMS = parseFloat(item.dPricePerSMS);
        // this.precioLlamadaEntrante = parseFloat(item.dPricePerIncomingCall);
        // this.precioLlamadaSaliente = parseFloat(item.dPricePerOutgoingCall);
        // this.dMonthlySMSLimit = parseFloat(item.dMonthlySMSLimit);
        // this.dMonthlyVoiceLimit = parseFloat(item.dMonthlyVoiceLimit);
      }
    },
  },
};
</script>

<style scoped>
.txt-label-plan-name {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  width: fit-content;
  padding: 0px 15px;
}

.txt-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
}

.content-buttons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
