<template>
  <div>
    <div v-if="Object.keys(items).length > 0">
      <layout-detail-client-header :items="items" />
      <layout-detail-client-photo :items="items" />
      <div class="separator mt-11 mb-11" />
      <layout-detail-client-information-general
        :items="items"
        @setOptions="setOptions"
      />
      <div class="separator mt-11 mb-11" />
    </div>

    <!-- #region Permisos -->
    <div class="content-permissions">
      <div class="content-label-txt-title poppins">Permisos</div>
      <div class="content-switch-all">
        <v-switch
          v-model="bCanActivateSIM"
          @change="setChangeCanActivateSIM"
          :loading="bLoadingCanActivateSIM"
          :disabled="bLoadingCanActivateSIM"
        >
          <template #label>
            <span class="ml-3 poppins txt-label-switch">
              Permiso de activación y desactivación de sus tarjetas SIM propias.
            </span>
          </template>
        </v-switch>
        <v-switch
          v-model="bCanChangeField"
          @change="setChangeCanChangeField"
          :loading="bLoadingChangeField"
          :disabled="bLoadingChangeField"
        >
          <template #label>
            <span class="ml-3 poppins txt-label-switch">
              Permiso de edición de campo 1 de tarjeta sim.
            </span>
          </template>
        </v-switch>
      </div>
    </div>
    <!-- #endregion Permisos -->

    <!-- <layout-detail-client-plans :items="items" @setAsignPlan="setAsignPlan" /> -->
    <div class="separator mt-11 mb-11" ></div>
    <!-- <layout-detail-client-custom-plans
      :items="items"
      @setAsignPlanEdit="setAsignPlanEdit"
      @setOptions="setOptions"
    /> -->
    <layout-detail-client-plans-top @setAsignPlanEdit="setAsignPlanEdit" :sEnterpriseId="sEnterpriseId" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
    <!-- modal asign plan -->
    <layout-detail-client-asign-plan
      :activeDialog="activeDialog"
      :objDialog="objDialog"
      @setAsignPlan="setAsignPlan"
    />
    <!-- modal edit plan -->
    <layout-detail-client-edit-plan
      :activeDialogEdit="activeDialogEdit"
      :objDialogEdit="objDialogEdit"
      @setAsignPlanEdit="setAsignPlanEdit"
    />
    <!-- delete component  -->
    <delete-component :oOptions="oOptions" @setOptions="setOptions" />
  </div>
</template>

<script>
import LayoutDetailClientHeader from "@/layouts/DetailClient/Header.vue";
import LayoutDetailClientInformationGeneral from "@/layouts/DetailClient/InformationGeneral.vue";
import LayoutDetailClientPlans from "@/layouts/DetailClient/Plans.vue";
import LayoutDetailClientCustomPlans from "@/layouts/DetailClient/CustomPlans.vue";
import LayoutDetailClientPhoto from "@/layouts/DetailClient/Photo.vue";
import LayoutDetailClientAsignPlan from "@/layouts/DetailClient/AsignPlan.vue";
import LayoutDetailClientEditPlan from "@/layouts/DetailClient/EditPlan.vue";
import LayoutDetailClientPlansTop from "@/layouts/DetailClient/PlansTop.vue";

export default {
  name: "DetailClientView",
  data() {
    return {
      bLoading: false,
      items: {},
      sEnterpriseId:null,
      activeDialog: false,
      objDialog: {},
      activeDialogEdit: false,
      objDialogEdit: {},
      oOptions: {},
      bCanActivateSIM: false,
      bLoadingCanActivateSIM: false,
      bCanChangeField: false,
      bLoadingChangeField: false,
    };
  },
  beforeMount() {
    this.clientId = this.$route.params.id;
    this.getData();
  },
  methods: {
    setOptions: function (val) {
      this.oOptions = val;
    },
    getData: function () {
      this.items = {};
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/enterprises/${this.clientId}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {
              iPageNumber: this.iCurrentPage,
              sSearch: this.sSearch,
              iItemsPerPage: this.iItemsPerPage,
            },
          })
            .then((response) => {
              this.bLoading = false;
              this.items = response.data.results;
              this.sEnterpriseId = response.data.results.sEnterpriseId;
              this.bCanActivateSIM = response.data.results.bCanActivateSIM;
              this.bCanChangeField = response.data.results.bCanChangeField;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
        });
    },
    setChangeCanActivateSIM() {
      this.bLoadingCanActivateSIM = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          bCanActivateSIM: this.bCanActivateSIM,
        };

      DB.patch(
        `${URI}/enterprises/${this.$route.params.id}/sim`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoadingCanActivateSIM = false;
        })
        .catch((error) => {
          this.bLoadingCanActivateSIM = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setChangeCanChangeField() {
      this.bLoadingChangeField = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          bCanChangeField: this.bCanChangeField,
        };

      DB.patch(
        `${URI}/enterprises/${this.$route.params.id}/field`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoadingChangeField = false;
        })
        .catch((error) => {
          this.bLoadingChangeField = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setAsignPlan: function (item) {
      this.activeDialog = item.activeDialog;
      this.objDialog = item.objDialog;
    },
    setAsignPlanEdit: function (item) {
      this.activeDialogEdit = item.activeDialogEdit;
      this.objDialogEdit = item.objDialogEdit;
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
  },
  components: {
    LayoutDetailClientHeader,
    LayoutDetailClientInformationGeneral,
    LayoutDetailClientPlans,
    LayoutDetailClientCustomPlans,
    LayoutDetailClientPhoto,
    LayoutDetailClientAsignPlan,
    LayoutDetailClientEditPlan,
    LayoutDetailClientPlansTop,
  },
};
</script>
<style scoped>
.content-switch-all {
  display: flex;
  gap: 15px;
}
.txt-label-switch {
  color: black;
  font-size: 0.9rem;
}
</style>
