<template>
    <div>
      <layout-sim-cards-local-header @setDialogAdd="setDialogAdd" />
      <layout-sim-cards-local-searchbar
        @setSearch="setSearch"
        @setPlanId="setPlanId"
        @setPayload="setPayload"
      />
      <layout-sim-cards-local-content
        :sSearch="sSearch"
        @setOptions="setOptions"
        :sPlanId="sPlanId"
        :oPayload="oPayload"
        @toTopGlobal="toTopGlobal"
      />
      <layout-sim-cards-local-add
        :bDialogAdd="bDialogAdd"
        @setDialogAdd="setDialogAdd"
      />
      <!-- general component  -->
      <delete-component
        :oOptions="oOptions"
        @setOptions="setOptions"
      />
    </div>
  </template>
  
  <script>
  import LayoutSimCardsLocalHeader from "@/layouts/SimCardsLocal/Header.vue";
  import LayoutSimCardsLocalContent from "@/layouts/SimCardsLocal/Content.vue";
  import LayoutSimCardsLocalSearchbar from "@/layouts/SimCardsLocal/Searchbar.vue";
  import LayoutSimCardsLocalAdd from "@/layouts/SimCardsLocal/Add.vue";
  
  export default {
    name: "SimCardsLocalView",
    data() {
      return {
        sSearch: "",
        bDialogAdd: false,
        oOptions: {},
        sPlanId: undefined,
        oPayload:{}
      };
    },
    methods: {
      setSearch: function (val) {
        this.sSearch = val;
      },
      setDialogAdd: function (val) {
        this.bDialogAdd = val;
      },
      setOptions: function (val) {
        this.oOptions = val;
      },
      setPlanId: function (val) {
        this.sPlanId = val;
      },
      setPayload(oPayload){
        this.oPayload = oPayload
      },
      toTopGlobal(){
        this.$emit("toTopGlobal")
      }
    },
    components: {
      LayoutSimCardsLocalHeader,
      LayoutSimCardsLocalContent,
      LayoutSimCardsLocalSearchbar,
      LayoutSimCardsLocalAdd,
    },
  };
  </script>