<template>
  <div class="content-detail-global">
    <div>
      <div class="content-general-information-and-subtotal">
        <div class="content-general-information">
          <content-general-infromation
            :oGeneralInformation="oGeneralInformation"
          />

          <div>
            <v-divider class="divider-vertical mt-5 mb-5"></v-divider>
          </div>

          <content-billing-information
            :oBillingInformation="oBillingInformation"
          />

          <div v-if="bMobile">
            <v-divider class="divider-vertical mt-5 mb-5"></v-divider>
          </div>
        </div>

        <div class="content-subtotal">
          <content-subtotales :oSubtotal="oSubtotal" />
        </div>
      </div>

      <div>
        <v-divider class="divider-vertical mt-5 mb-5"></v-divider>
      </div>

      <content-consumptions
        :aConsumption="aConsumptionList"
        :tFinalDate="tFinalDate"
        :bLoading="bLoading"
      />

      <content-additional-products :aAdditionalProduct="aAdditionalProduct" />

      <content-discounts :aDiscounts="aDiscountsList" />
      <div>
        <v-divider class="divider-vertical mt-5 mb-5"></v-divider>
      </div>

      <dialog-confirm-global
        :bDialogConfirmGlobal="bDialogConfirmGlobal"
        :oItem="oItemDialogSendEmail"
        @setDialogConfirmGlobal="sendEmail"
      />
    </div>
  </div>
</template>

<script>
import ContentGeneralInfromation from "./ContentGeneralInfromation.vue";
import ContentBillingInformation from "./ContentBillingInformation.vue";
import ContentConsumptions from "./ContentConsumptions.vue";
import ContentAdditionalProducts from "./ContentAdditionalProducts.vue";
import ContentDiscounts from "./ContentDiscounts.vue";
import ContentSubtotales from "./ContentSubtotales.vue";

export default {
  props: {
    items: Object,
    oExpense: Object,
    aDiscounts: Array,
    aProducts: Array,
    aConsumption: Array,
    bLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bLoading: false,
      sPrebillingId: "",
      oGeneralInformation: {},
      oBillingInformation: {},
      oSubtotal: {},
      aConsumptionList: [],
      tFinalDate: "",
      aAdditionalProduct: [],
      aDiscountsList: [],
      bMobile: false,
      bDialogConfirmGlobal: false,
      oItemDialogSendEmail: {},
    };
  },
  beforeMount() {
    this.sPrebillingId = this.$route.params.id;

    this.fullDataItem(this.items);
    this.fullDataExpense(this.oExpense);
    // this.getPrebillingDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 960) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    fullDataItem(oItem) {
      this.oGeneralInformation = {
        sFolio: oItem.sFolio,
        sClient: oItem.oEnterprise.sPublicName,
        sStatus: oItem.sStatus,
        sPeriod:
          oItem.oPeriod.tInitialDate.substr(0, 11).toString().toUpperCase() +
          " / " +
          oItem.oPeriod.tFinalDate.substr(0, 11).toString().toUpperCase(),
      };
      this.tFinalDate = oItem.oPeriod.tFinalDate;
      this.oBillingInformation = {
        sBusinessName: oItem.oEnterprise.oTaxInfo.sName,
        sLocationBilling:
          oItem.oEnterprise.oTaxInfo.oAddress.sFullTaxLocationAddress,
        sEmail: oItem.oEnterprise.sEmail,
        sTaxt: oItem.oEnterprise.sTaxt,
      };
      this.oSubtotal = oItem.oSummary;
    },
    fullDataExpense(oExpense) {
      this.aConsumptionList = oExpense.aConsumption;
      this.aAdditionalProduct = oExpense.aProducts;
      this.aDiscountsList = oExpense.aDiscounts;
    },
    discardChange() {
      this.fullData();
    },
    back() {
      this.$router.push({ name: "prebilling" }).catch((e) => {});
    },
    sendEmail() {
      let oItem = {
        sTitleDialog: "Enviar prefactura por correo",
        sQuestion: `<span>¿Estas seguro de enviar la prefactura ${this.oGeneralInformation.sFolio}?</span><br/><span>Esta acción enviará un correo a ${this.oBillingInformation.sEmail}</span>`,
        sDoubleConfirmation: true,
        sConfirmation: `Acepto la responsabilidad y deseo proseguir con el envío de la prefactura: ${this.oGeneralInformation.sFolio}`,
        sApi: "",
        sNameBtnAction: "Enviar",
      };
      this.oItemDialogSendEmail = oItem;
      this.bDialogConfirmGlobal = !this.bDialogConfirmGlobal;
    },
  },
  watch: {
    items() {
      this.fullDataItem(this.items);
    },
    oExpense() {
      this.fullDataExpense(this.oExpense);
    },
    // oDiscounts(){
    //   this.fullDataExpense(this.oDiscounts);
    // },
    // oProducts(){
    //   this.fullDataExpense(this.oProducts);
    // },
    // oConsumption(){
    //   this.fullDataExpense(this.oConsumption);
    // }
  },
  components: {
    ContentGeneralInfromation,
    ContentBillingInformation,
    ContentConsumptions,
    ContentAdditionalProducts,
    ContentDiscounts,
    ContentSubtotales,
  },
};
</script>

<style scoped>
.content-general-information-and-subtotal {
  display: flex;
  width: 100%;
}

.content-general-information {
  width: 100%;
  margin-right: 15px;
}

/*#region GLOBAL CONTENT BTN */
.content-btns-global {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.content-btn-edit {
  width: 150px;
}

/*#endregion GLOBAL CONTENT BTN */

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information-and-subtotal {
    display: block;
    width: 100%;
  }

  .content-general-information {
    width: 100%;
    margin-right: 15px;
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }

  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-general-information-and-subtotal {
    display: block;
    width: 100%;
  }

  .content-general-information {
    width: 100%;
    margin-right: 15px;
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }

  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
