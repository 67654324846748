<template>
  <div class="content-tabs-cards">
    <div class="sticky-content">
      <layout-detail-sim-cards-local-header
        :bLteEnabled="bLteEnabled"
        :oFixedInfo="oFixedInfo"
      />
      <layout-detail-sim-cards-local-dashboard
        :oFixedInfo="oFixedInfo"
        :oCustomer="oCustomer"
      />
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#2759A2"
        align-with-title
        center-active
        show-arrows
        :touchless="true"
      >
        <v-tabs-slider class="px-slider"></v-tabs-slider>
        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </div>
    <layout-detail-sim-cards-local-tabs
      :tab="tab"
      @setFixedInfoDetail="setFixedInfo"
      @setLteEnabledDetail="setLteEnabled"
      @setCustomer="setCustomer"
    />
  </div>
</template>

<script>
import LayoutDetailSimCardsLocalHeader from "@/layouts/DetailSimCardsLocal/Header.vue";
import LayoutDetailSimCardsLocalDashboard from "@/layouts/DetailSimCardsLocal/Dashboard.vue";
import LayoutDetailSimCardsLocalTabs from "@/layouts/DetailSimCardsLocal/Tabs.vue";

export default {
  name: "DetailSimCardView",
  data() {
    return {
      tab: 0,
      tabs: [
        { text: "Información de la SIM", component: "LayoutTabsInformacion" },
        { text: "Estado de ciclo de vida", component: "LayoutTabsCicloVida" },
        { text: "Presencia", component: "LayoutTabsPresencia" },
        {
          text: "Control de consumo de tráfico",
          component: "LayoutTabsControlConsumo",
        },
        { text: "Localización", component: "LayoutTabsLocalizacion" },
        // { text: "LOG de auditoría", component: "LayoutTabsAuditoria" },
      ],
      oFixedInfo: {},
      bLteEnabled: null,
      oCustomer: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      // setup click event for next icon
      document
        .getElementsByClassName("v-slide-group__next")[0]
        .addEventListener("click", () => {
          this.tab = this.tab + 1;
        });
      // setup click event for previous icon
      document
        .getElementsByClassName("v-slide-group__prev")[0]
        .addEventListener("click", () => {
          this.tab = this.tab - 1;
        });
    });
  },
  components: {
    LayoutDetailSimCardsLocalHeader,
    LayoutDetailSimCardsLocalDashboard,
    LayoutDetailSimCardsLocalTabs,
  },
  methods: {
    setFixedInfo(oFixedInfo) {
      this.oFixedInfo = oFixedInfo;
    },
    setLteEnabled(bLteEnabled) {
      this.bLteEnabled = bLteEnabled;
    },
    setCustomer(oCustomer) {
      this.oCustomer = oCustomer;
    },
  },
  watch: {
    tab() {},
  },
};
</script>

<style>
.content-tabs-cards .v-tabs-slider-wrapper {
  height: 5px !important;
}

.content-tabs-cards .v-tab {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a1acc3 !important;
  opacity: 1;
  text-transform: initial;
}

.content-tabs-cards .v-tab--active {
  color: #2759a2 !important;
}

.content-tabs-cards .theme--light.v-tabs-items {
  background-color: transparent;
}

.v-slide-group__wrapper {
  border-bottom: 1px solid #a1acc3 !important;
}
</style>

<style scoped>
.px-slider {
  background: transparent linear-gradient(268deg, #79defc 0%, #2759a2 100%) 0%
    0% no-repeat padding-box;
}

.sticky-content {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #f2f6fe;
}

@media (max-width: 960px) {
  .sticky-content {
    position: inherit !important;
  }
}
</style>
