<template>
  <div class="content-detail-global">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="#2759A2"
      align-with-title
      center-active
      show-arrows
      :touchless="true"
    >
      <v-tabs-slider class="px-slider"></v-tabs-slider>
      <v-tab v-for="(item, index) in tabs" :key="index">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" :touchless="true">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <KeepAlive>
          <component
            :tab="iTab"
            :is="item.component"
            :items="items"
            :oExpense="item.oItemsExpense"
            :bLoading="bLoading"
          />
        </KeepAlive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LayoutContentPreInvoice from "./ContentPreInvoice/Content.vue";
import LayoutContentPaid from "./ContentPaid/Content.vue";

export default {
  name: "LayoutDetailPreBillingTabs",
  props: {
    items: Object,
    oExpense: Object,
    aDiscounts: Array,
    aProducts: Array,
    aConsumption: Array,
    oPayments: Object,
    bRefreshFill: Boolean,
    bLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tab: 0,
      tabs: [],
      iTab: null,
    };
  },
  beforeMount() {
    this.fillTab();
  },
  components: {
    LayoutContentPreInvoice,
    LayoutContentPaid,
  },
  methods: {
    fillTab() {
      this.tabs = [
        {
          text: "Prefactura",
          component: "LayoutContentPreInvoice",
          oItemsExpense: {
            aProducts: this.aProducts,
            aConsumption: this.aConsumption,
            aDiscounts: this.aDiscounts,
          },
        },
        {
          text: "Pagos",
          component: "LayoutContentPaid",
          oItemsExpense: this.oPayments,
        },
      ];
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      this.iTab = this.tab;
      this.$emit("setTab", this.iTab);
    },
    bRefreshFill() {
      this.fillTab();
    },

    // aConsumption() {
    //     this.fillTab();
    // },
    // aProducts() {
    //     this.fillTab();
    // },
    // aDiscounts() {
    //     this.fillTab();
    // },
    // oPayments() {
    //     this.fillTab();
    // }
  },
};
</script>

<style>
.content-detail-global .v-tabs-slider-wrapper {
  height: 5px !important;
}

.content-detail-global .v-tab {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a1acc3 !important;
  opacity: 1;
  text-transform: initial;
}

.content-detail-global .v-tab--active {
  color: #2759a2 !important;
}

.content-detail-global .theme--light.v-tabs-items {
  background-color: transparent;
}
</style>

<style scoped>
.px-slider {
  background: transparent linear-gradient(268deg, #79defc 0%, #2759a2 100%) 0%
    0% no-repeat padding-box;
}
</style>
