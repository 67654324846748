<template>
  <div>
    <div class="content-title-section title-section-dashboard mb-2">
      Planes asignados al cliente
    </div>
    <div
      class="content-dashboard content-empty-plans-dashboard txt-empty-global"
      ref="ContentDashboardPlan"
      v-if="aPlans.length === 0"
    >
      No hay información
    </div>
    <div
      v-else
      class="content-dashboard overflow-hidden "
      ref="ContentDashboardPlan"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          :md="bChangeSize ? '12' : '6'"
          :lg="bChangeSize ? '12' : '6'"
          xl="6"
          v-for="(item, i) in aPlans"
          :key="i"
        >
          <div
            class="content-dashboard-card cursor-pointer"
            @click="editPlan(item)"
          >
            <div class="content-dashboard-left">
              {{ item.sName }}
            </div>
            <div class="divider-dashboard-card" />

            <div class="content-dashboard-right">
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card">
                  Activa:
                  <span class="txt-result-dashboard-card color-green mr-2">{{
                    item.iActive
                  }}</span>
                </span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card">
                  Inactiva:
                  <span class="txt-result-dashboard-card color-red mr-2">{{
                    item.iInactive
                  }}</span>
                </span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card"
                  >Lista para activar:
                  <span class="txt-result-dashboard-card color-yellow mr-2">{{
                    item.iReadyToActivate
                  }}</span>
                </span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card"
                  >Prueba:
                  <span class="txt-result-dashboard-card color-grey">{{
                    item.iTest
                  }}</span>
                </span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card"
                  >Pool de datos:
                  <span class="txt-result-dashboard-card font-bold"
                    >{{ item.sDataOfPool }} MB</span
                  >
                </span>
              </div>
              <div class="content-title-result-dashboard">
                <span class="txt-title-dashboard-card"
                  >Precio de renta:
                  <span class="txt-result-dashboard-card font-bold">
                    ${{ formatMoneyGlobal(item.dRentalPrice) }} MXN</span
                  >
                </span>
              </div>

              <div class="content-title-result-dashboard">
                <v-progress-linear
                  height="12"
                  rounded
                  :color="getColorGlobal(item.dCurrent, item.sDataOfPool)"
                  :value="item.dProcessBar"
                >
                  <span class="txt-progress-linear"
                    >{{ item.dCurrent }} MB/{{ item.sDataOfPool }} MB</span
                  >
                </v-progress-linear>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sEnterpriseId: String,
  },
  data() {
    return {
      aPlans: [

      ],
      bLoading: false,
      bChangeSize: false,
      iHeight: 0,
    };
  },
  updated() {
    this.onResize();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  beforeMount() {
    this.getTopPlans();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.iHeight = window.innerHeight - 90;
      //   if (this.$refs.ContentDashboardPlan.clientHeight > 0) {
      //     this.$store.commit(
      //       "setContentPlansDashboard",
      //       this.$refs.ContentDashboardPlan.clientHeight
      //     );
      //   }

      if (this.bMenu) {
        if (window.innerWidth > 959.99 && window.innerWidth < 1421) {
          this.bChangeSize = true;
        } else {
          this.bChangeSize = false;
        }
      } else {
        if (window.innerWidth > 959.99 && window.innerWidth < 1221) {
          this.bChangeSize = true;
        } else {
          this.bChangeSize = false;
        }
      }
    },
    getTopPlans() {
      this.bLoading = true;
      this.aPlans = [];
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            DB.get(
              `${URI}/enterprises/${this.$route.params.id}/planConsumptions`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {},
              }
            )
              .then((response) => {
                this.aPlans = response.data.results.map((e, i) => {
                  return {
                    sCustomPlanId: e.sCustomPlanId,
                    sEnterpriseId: this.sEnterpriseId,
                    sName: e.sName,
                    iActive: e.oTotalSIMCards.iActiveSIM,
                    iInactive: e.oTotalSIMCards.iDeactiveSIM,
                    iReadyToActivate: e.oTotalSIMCards.iActivationReady,
                    iTest: e.oTotalSIMCards.iTestSIM,
                    sDataOfPool: e.oDataConsumption.dPool,
                    dRentalPrice: e.oDataConsumption.dUnitPrice,
                    dCurrent: e.oDataConsumption.dCurrent,
                    // dProcessBar:
                    //   (e.oDataConsumption.dCurrent / e.oDataConsumption.dPool) *
                    //   100,
                    dProcessBar: e.oDataConsumption.dPercentConsumed,
                  };
                });
                this.bLoading = false;

                this.$store.commit("refresher", false);
              })
              .catch((error) => {
                this.bLoading = false;

                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
        });
    },
    editPlan: function (item) {
      this.$emit("setAsignPlanEdit", {
        activeDialogEdit: true,
        objDialogEdit: item,
      });
    },
  },
  computed: {
    bMenu() {
      return this.$store.state.bMenu;
    },
  },
};
</script>
<style scoped>
.content-dashboard-plans {
  /* overflow: hidden; */
  /* height: 206px !important; */
  padding: 20px 14px 20px 14px !important;
  min-height: auto !important;
  max-height: auto !important;
}
</style>
<style scoped>
.content-empty-plans-dashboard {
  height: 221px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt-progress-linear {
  font-size: 0.6rem;
}
</style>
