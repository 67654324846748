<template>
  <div>
    <div class="content-title-section">Información de la tarjeta SIM</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="4">
          <div>
            <p class="txt-label-global">Número ICC</p>
            <p class="txt-value-global">{{ oSimsCard.sICC }}</p>
          </div>
        </v-col>
        <v-col cols="8">
          <div>
            <p class="txt-label-global">Cliente asociado</p>
            <p
              v-if="oSimsCard.sFinalCustomer !== 'N/A'"
              class="txt-client-assign"
            >
              {{ oSimsCard.sFinalCustomer }}
            </p>
            <p v-else class="txt-client-assign-empty txt-empty-global">
              Tarjeta no asignada
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <div class="content-title-section">Información de dispositivo</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">IMEI</p>
            <p class="txt-value-global">{{ oSimsCard.sIMEI }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">
              Modelo del módulo de comunicaciones GSM
            </p>
            <p class="txt-value-global">{{ oGSM.sComunicationModule }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">
              Fabricante del módulo de comunicaciones GSM
            </p>
            <p class="txt-value-global">{{ oGSM.sManufacturerModule }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <div class="content-title-section">Campos personalizados</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Cuenta</p>
            <v-text-field
              v-model="sAccount"
              type="text"
              label=""
              placeholder="Cuenta"
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oSimsCard.sAlias }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">ID</p>
            <v-text-field
              v-model="sID"
              type="text"
              label=""
              placeholder="ID"
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oSimsCard.sId }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Plan de datos</p>
            <v-text-field
              v-model="sDataPlan"
              type="text"
              label=""
              placeholder="Plan de datos"
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oSimsCard.sDataPlan }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Fecha de envío</p>

            <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="tDeliveryDate"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="tDeliveryDate" label="" dense class="global-inputs poppins mb-0"  readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="tDeliveryDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(tDeliveryDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu> -->

            <v-text-field
              v-model="tDeliveryDate"
              type="text"
              label=""
              placeholder="Fecha de envío"
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oDates.tDeliveryDate }}</p> -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Campo personalizado 1</p>
            <v-text-field
              v-model="sField_1"
              type="text"
              label=""
              placeholder=""
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oCustomFields.sField_1 }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Campo personalizado 2</p>
            <v-text-field
              v-model="sField_2"
              type="text"
              label=""
              placeholder=""
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oCustomFields.sField_2 }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Campo personalizado 3</p>
            <v-text-field
              v-model="sField_3"
              type="text"
              label=""
              placeholder=""
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oCustomFields.sField_3 }}</p> -->
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Campo personalizado 4</p>
            <v-text-field
              v-model="sField_4"
              type="text"
              label=""
              placeholder=""
              dense
              color="#2759A2"
              class="global-inputs poppins mb-9"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
            <!-- <p class="txt-value-global">{{ oCustomFields.sField_4 }}</p> -->
          </div>
        </v-col>
      </v-row>
      <div class="content-buttons" v-if="getPermissionsActionsGlobal()">
        <v-container class="pa-0" fluid>
          <div class="content-btns">
            <div class="btn-discard">
              <v-btn
                @click="discardChange"
                v-if="validationForm"
                class="button-secondary poppins"
                elevation="0"
                width="100%"
              >
                Descartar cambios
              </v-btn>
            </div>
            <div class="btn-add">
              <v-btn
                @click="addFieldAll"
                :loading="bLoading"
                :disabled="!validationForm"
                class="button-primary poppins"
                elevation="0"
                width="100%"
              >
                Guardar cambios
              </v-btn>
            </div>
          </div>
        </v-container>
      </div>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <div class="content-title-section">Trazabilidad</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12">
          <div>
            <p class="txt-label-global">Fecha de primera activación</p>
            <p class="txt-value-global">{{ oDates.tActivationDate }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <div class="content-title-section">Parametros de red</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Estado actual de ciclo de vida</p>
            <p class="txt-value-global">{{ oStatus.sName }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Número telefónico</p>
            <p class="txt-value-global">{{ oSimsCard.sPhoneNumber }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global">Estado LTE</p>
            <p class="txt-value-global">
              {{ oSimsCard.bLteEnabled ? "Activo" : "Inactivo" }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="txt-label-global d-flex align-center">
              APN asignado al SIM
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="15"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >Si el APN reflejado no es el que utiliza habitualmente, por
                  favor solicite el cambio a su ejecutivo</span
                >
              </v-tooltip>
            </p>
            <p v-if="oSimsCard.sAPN" class="txt-value-global">
              {{ oSimsCard.sAPN }}
            </p>
            <p v-else class="txt-empty-global">{{ sTextEmpty }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
  </div>
</template>

<script>
export default {
  name: "layoutTabsInformacion",
  props: {
    tab: Number,
  },
  data() {
    return {
      sSIMCardId: "",
      oSimsCard: {},
      oConsumptionMonthly: {},
      oCustomFields: {},
      oDates: {},
      oEnterprise: {},
      oGPRSStatus: {},
      oGSM: {},
      oStatus: {},
      oFixedInfo: {},
      oCustomFieldsKITE: {},
      oCustomFields: {},
      bLoading: false,

      sAccount_Origin: "",
      sAccount: "",

      sID_Origin: "",
      sID: "",

      sDataPlan_Origin: "",
      sDataPlan: "",

      tDeliveryDate_Origin: "",
      tDeliveryDate: "",

      sField_1_Origin: "",
      sField_1: "",

      sField_2_Origin: "",
      sField_2: "",

      sField_3_Origin: "",
      sField_3: "",

      sField_4: "",
      sField_4_Origin: "",

      bLoading: false,

      date: "",
      menu: false,
    };
  },
  beforeMount() {
    this.getInformation();
  },
  activated() {
    if (this.tab == 0) {
      this.getInformation();
    }
  },
  methods: {
    getInformation() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URL_KITE_LOCAL}/${this.$route.params.id}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              this.sSIMCardId = response.data.results.sSIMCardId;
              this.oDates = response.data.results.oDates;
              this.oCustomFields = response.data.results.oCustomFields;

              this.sField_1_Origin = this.oCustomFields.sField_1;
              this.sField_1 = this.oCustomFields.sField_1;

              this.sField_2_Origin = this.oCustomFields.sField_2;
              this.sField_2 = this.oCustomFields.sField_2;

              this.sField_3_Origin = this.oCustomFields.sField_3;
              this.sField_3 = this.oCustomFields.sField_3;

              this.sField_4_Origin = this.oCustomFields.sField_4;
              this.sField_4 = this.oCustomFields.sField_4;

              this.oSimsCard = response.data.results;

              this.sAccount_Origin = this.oSimsCard.sAlias;
              this.sAccount = this.oSimsCard.sAlias;

              this.sID_Origin = this.oSimsCard.sId;
              this.sID = this.oSimsCard.sId;

              this.sDataPlan_Origin = this.oSimsCard.sDataPlan;
              this.sDataPlan = this.oSimsCard.sDataPlan;

              this.tDeliveryDate_Origin = this.oDates.tDeliveryDate;
              this.tDeliveryDate = this.oDates.tDeliveryDate;

              this.oConsumptionMonthly =
                response.data.results.oConsumptionMonthly;
              this.oCustomFields = response.data.results.oCustomFields;
              this.oEnterprise = response.data.results.oEnterprise;
              this.oGPRSStatus = response.data.results.oGPRSStatus;
              this.oGSM = response.data.results.oGSM;
              this.oStatus = response.data.results.oStatus;
              this.oFixedInfo = response.data.results.oFixedInfo;
              this.$emit("setFixedInfo", this.oFixedInfo);
              this.$emit("setLteEnabled", this.oSimsCard.bLteEnabled);
              this.$emit("setCustomer", {
                sId: this.oSimsCard.sFinalCustomerId,
                sName: this.oSimsCard.sFinalCustomer,
                sICC: this.oSimsCard.sICC,
              });
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    discardChange() {
      this.sField_1 = this.oCustomFields.sField_1;
      this.sField_2 = this.oCustomFields.sField_2;
      this.sField_3 = this.oCustomFields.sField_3;
      this.sField_4 = this.oCustomFields.sField_4;
    },
    addFieldAll() {
      if (this.getValidFieldsKITE()) {
        this.addFieldsKITE();
      }
      if (this.getValidFields()) {
        this.addFields();
      }
    },
    addFieldsKITE() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                // sAlias: this.sAccount,
                // sCustomField1: this.sID,
                // sCustomField3: this.sDataPlan,
                // sCustomField4: this.tDeliveryDate,
              };

            if (this.sAccount !== this.sAccount_Origin) {
              payload["sAlias"] = this.sAccount;
            }
            if (this.sID !== this.sID_Origin) {
              payload["sCustomField1"] = this.sID;
            }
            if (this.sDataPlan !== this.sDataPlan_Origin) {
              payload["sCustomField3"] = this.sDataPlan;
            }
            if (this.tDeliveryDate !== this.tDeliveryDate_Origin) {
              payload["sCustomField4"] = this.tDeliveryDate;
            }

            DB.put(`${URL_KITE_LOCAL}/${this.$route.params.id}`, payload, config)
              .then((response) => {
                this.mixSuccess(response.data.message);
                this.bLoading = false;
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
                this.bLoading = false;
              });
          } else {
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.bLoading = false;
            // this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
          this.bLoading = false;
          // this.$store.commit("refresher", true);
        });
    },
    addFields() {
      this.bLoading = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          // sCustomField1: this.sField_1,
          // sCustomField2: this.sField_2,
          // sCustomField3: this.sField_3,
          // sCustomField4: this.sField_4,
        };

      if (this.sField_1 !== this.sField_1_Origin) {
        payload["sCustomField1"] = this.sField_1;
      }
      if (this.sField_2 !== this.sField_2_Origin) {
        payload["sCustomField2"] = this.sField_2;
      }
      if (this.sField_3 !== this.sField_3_Origin) {
        payload["sCustomField3"] = this.sField_3;
      }
      if (this.sField_4 !== this.sField_4_Origin) {
        payload["sCustomField4"] = this.sField_4;
      }

      DB.patch(`${URI}/sim-cards/${this.sSIMCardId}`, payload, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.bLoading = false;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.bLoading = false;
        });
    },
    getValidFieldsKITE() {
      return (
        this.sAccount !== this.sAccount_Origin ||
        this.sID !== this.sID_Origin ||
        this.sDataPlan !== this.sDataPlan_Origin ||
        this.tDeliveryDate !== this.tDeliveryDate_Origin
      );
    },
    getValidFields() {
      return (
        this.sField_1 !== this.sField_1_Origin ||
        this.sField_2 !== this.sField_2_Origin ||
        this.sField_3 !== this.sField_3_Origin ||
        this.sField_4 !== this.sField_4_Origin
      );
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
    validationForm: function () {
      return (
        this.sField_1 !== this.oCustomFields.sField_1 ||
        this.sField_2 !== this.oCustomFields.sField_2 ||
        this.sField_3 !== this.oCustomFields.sField_3 ||
        this.sField_4 !== this.oCustomFields.sField_4 ||
        this.sAccount !== this.oSimsCard.sAlias ||
        this.sID !== this.oSimsCard.sId ||
        this.sDataPlan !== this.oSimsCard.sDataPlan ||
        this.tDeliveryDate !== this.oDates.tDeliveryDate
        // this.sField_1 != "" && this.sField_1 != null && this.sField_1 !== this.oCustomFields.sField_1  &&
        // this.sField_2 != "" && this.sField_2 != null  && this.sField_2 !== this.oCustomFields.sField_2 &&
        // this.sField_3 != "" && this.sField_3 != null && this.sField_3 !== this.oCustomFields.sField_3  &&
        // this.sField_4 != "" && this.sField_4 != null && this.sField_4 !== this.oCustomFields.sField_4
      );
    },
    sTextEmpty() {
      return this.$store.state.sTextEmpty;
    },
  },
  watch: {
    tab() {
      if (this.tab === 0) {
        this.getInformation();
      }
    },
    refresh() {
      this.getInformation();
    },
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.content-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.btn-discard {
  margin-right: 10px;
}

.txt-client-assign-empty {
  margin-bottom: 0px;
  font-size: 14px;
}

@media (max-width: 599.99px) {
  .content-btns {
    display: block;
  }

  .btn-discard {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
</style>
