<template>
  <div class="content-options-sims-card">
    <p class="txt-actions poppins">Acciones globales:</p>
    <v-btn
      elevation="0"
      @click="setDialogConfirmActionGlobal(1)"
      :disabled="selectedItems.length == 0"
      class="button-secondary btn-options-sims-card poppins"
    >
      Reiniciar tarjeta
    </v-btn>
    <v-btn
      elevation="0"
      @click="sendSms()"
      :disabled="selectedItems.length == 0"
      class="button-secondary btn-options-sims-card poppins"
      >Enviar SMS
    </v-btn>
    <v-btn
      elevation="0"
      @click="changeStatus(true)"
      :disabled="selectedItems.length == 0"
      class="button-secondary btn-options-sims-card poppins"
      >Activar
    </v-btn>
    <v-btn
      elevation="0"
      @click="changeStatus(false)"
      :disabled="selectedItems.length == 0"
      class="button-secondary btn-options-sims-card poppins"
      >Desactivar
    </v-btn>
    <confirm-reset-sim
      @setDialogConfirmActionGlobal="setDialogConfirmActionGlobal"
      :bDialogConfirmResetSIM="bDialogConfirmResetSIM"
      :oItem="oItem"
      :selectedItems="selectedItems"
      sType="global"
    />

    <confirm-change-status-component
      :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus"
    />
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutSimsCardsOptionsDesktop",
  props: {
    selectedItems: Array,
  },
  components: {
    ConfirmChangeStatusComponent,
  },
  data() {
    return {
      bDialogConfirmResetSIM: false,
      oItem: {},
      oChangeStatusOptions: {},
    };
  },
  methods: {
    sendSms: function () {
      this.$store.commit("setDialogSendSms", true);
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URI}/sim-cards/global/${bAction ? "activate" : "deactivate"}`,
        payload: { aICC: this.selectedItems },
        bInputs: true,
        title:
          bAction == true
            ? "Activación de tarjeta SIM"
            : "Desactivación de tarjeta SIM",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción activara la tarjeta SIM.</br>¿Desea continuar?"
            : "La siguiente acción desactivara la tarjeta SIM.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Activar" : "Desactivar",
        iMethod: 1,
      };
    },
    setDialogConfirmActionGlobal(iActionGlobal) {
      this.bDialogConfirmResetSIM = !this.bDialogConfirmResetSIM;
      if (this.bDialogConfirmResetSIM) {
        this.oItem = this.setItemDynamic(iActionGlobal);
      }
    },
    setItemDynamic(iActionGlobal) {
      switch (iActionGlobal) {
        case 1:
          return {
            sTitle: "Reiniciar tarjeta",
            sDescription: "Selecciona la red(es) que deseas reiniciar",
            iActionGlobal: iActionGlobal,
          };
        case 2:
          return {
            sTitle: "Enviar SMS",
            sDescription: "Descripción de enviar SMS",
            iActionGlobal: iActionGlobal,
          };
        case 3:
          return {
            sTitle: "Activar",
            sDescription: "Descripción de activar",
            iActionGlobal: iActionGlobal,
          };
        case 4:
          return {
            sTitle: "Desactivar",
            sDescription: "Descripción de desactivar",
            iActionGlobal: iActionGlobal,
          };

        default:
          return {
            sTitle: "",
            sDescription: "",
            iActionGlobal: 0,
          };
      }
    },
    getPermissionsClient() {},
  },
};
</script>

<style scoped>
.content-options-sims-card {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt-actions {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.button-secondary:disabled {
  opacity: 0.7;
  border: 1px solid transparent !important;
}

.btn-options-sims-card {
  margin-left: 10px;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-options-sims-card {
    display: block;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-options-sims-card {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
