<template>
    <div>
        <div class="content-title-section mb-2">Plan personalizados</div>
        <div class="content-dashboard content-dashboard-plans" ref="ContentDashboardPlan">
            <v-row>
                <v-col cols="12" sm="12" :md="bChangeSize?'12':'6'" :lg="bChangeSize?'12':'6'" xl="6" v-for="(item,i) in aPlans" :key="i">
                    <div class="content-dashboard-card  ">
                        <div class="content-dashboard-left">
                            {{item.sName}}
                        </div>
                        <div class="divider-dashboard-card" />
                        <div class="content-dashboard-right">
                            <div class="content-title-result-dashboard">
                                <span class="txt-title-dashboard-card">
                                    Activa:
                                    <span class="txt-result-dashboard-card color-green mr-2">{{item.iActive}}</span>
                                </span>
                                <span class="txt-title-dashboard-card">
                                    Inactiva:
                                    <span class="txt-result-dashboard-card color-red mr-2">{{item.iInactive}}</span>
                                </span>
                                <span class="txt-title-dashboard-card">Lista para activar:
                                    <span
                                        class="txt-result-dashboard-card color-yellow">{{item.iReadyToActivate}}</span>
                                </span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <span class="txt-title-dashboard-card">Pool de datos:
                                    <span class="txt-result-dashboard-card font-bold">{{item.sDataOfPool}}</span>
                                </span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <span class="txt-title-dashboard-card">Precio de renta:
                                    <span class="txt-result-dashboard-card font-bold">
                                        {{item.dRentalPrice}}</span>
                                </span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <v-progress-linear height="12" :value="item.dProcessBar"></v-progress-linear>
                            </div>
                        </div>
                    </div>
                </v-col>
                <div
                class="content-dashboard d-flex justify-center txt-empty-global"
                v-if="aPlans.length === 0"
              >
                No hay información
              </div>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            aPlans: [
                {
                    sName: "5MB Global",
                    iActive: 999,
                    iInactive: 999,
                    iReadyToActivate: 999,
                    sDataOfPool: "1550 MB",
                    dRentalPrice: 999999,
                    dProcessBar: 20
                },
                {
                    sName: "5MB Global",
                    iActive: 999,
                    iInactive: 999,
                    iReadyToActivate: 999,
                    sDataOfPool: "1550 MB",
                    dRentalPrice: 999999,
                    dProcessBar: 20
                },
                {
                    sName: "5MB Global",
                    iActive: 999,
                    iInactive: 999,
                    iReadyToActivate: 999,
                    sDataOfPool: "1550 MB",
                    dRentalPrice: 999999,
                    dProcessBar: 20
                },
                {
                    sName: "5MB Global",
                    iActive: 999,
                    iInactive: 999,
                    iReadyToActivate: 999,
                    sDataOfPool: "1550 MB",
                    dRentalPrice: 999999,
                    dProcessBar: 20
                },
            
 
            ],
            bChangeSize:false,
            iHeight: 0
        }
    },
    updated() {
        this.onResize()
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize() {

            this.iHeight = window.innerHeight - 90;
            if (this.$refs.ContentDashboardPlan.clientHeight > 0) {
                this.$store.commit("setContentPlansDashboard", this.$refs.ContentDashboardPlan.clientHeight)
            }

            if (this.bMenu) {
                if (window.innerWidth > 959.99 && window.innerWidth < 1421) {
                    this.bChangeSize = true;
                } else {
                    this.bChangeSize = false;
                }
            } else {
                if (window.innerWidth > 959.99 && window.innerWidth < 1221) {
                    this.bChangeSize = true;

                } else {
                    this.bChangeSize = false;
                }
            }
        },
    },
    computed: {
        bMenu() {
            return this.$store.state.bMenu
        }
    },
}
</script>
<style>
.content-dashboard-plans {
    overflow: auto;
    height: auto !important;
    min-height: 120px ;
    max-height: 215px ;
}
</style>

