<template>
  <div>
    <!-- #region Información del representante -->
    <div class="content-title-label mb-4">Información del representante</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="sManagerName"
            type="text"
            label="Nombre"
            placeholder="Nombre"
            dense
            color="#2759A2"
            class="global-inputs poppins"
            background-color="transparent"
            maxlength="85"
            persistent-placeholder
            @keypress="soloLetters"
            @keyup="listenChange"
            :disabled="!getPermissionsActionsGlobal()"
          >
            <template slot="label">
              <span>Nombre(s) <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerLastname"
              type="text"
              label="Apellido"
              placeholder="Apellido"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Apellido(s) <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerJobTitle"
              type="text"
              label="Cargo laboral"
              placeholder="Cargo laboral"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <!-- Start: phone component -->
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisManager"
              @setCodigoArea="setCodigoAreaManager"
              @setNumeroTelefono="setNumeroTelefonoManager"
              @setExtension="setExtensionManager"
              :_bDialogAdd="false"
              :_codigoPais="sManagerCountryCallingCode"
              :_codigoArea="sManagerAreaCallingCode"
              :_numeroTelefono="sManagerPhoneNumber"
              :_extension="sManagerPhoneExtension"
              :_type="true"
              @keyup="listenChange"
            />
            <!-- End: phone component -->
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- #endregion Información del representante -->

    <!-- #region Información de la empresa -->
    <div class="content-title-label mt-7 mb-4">Información de la empresa</div>
    <v-container class="pl-0 pr-0" fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sPublicName"
              type="text"
              label="Nombre comercial"
              placeholder="Nombre comercial"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keyup="listenChange"
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Nombre comercial <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <!-- Start: phone component -->
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisEmpresa"
              @setCodigoArea="setCodigoAreaEmpresa"
              @setNumeroTelefono="setNumeroTelefonoEmpresa"
              @setExtension="setExtensionEmpresa"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="true"
              @keyup="listenChange"
            />
            <!-- End: phone component -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" md="6">
          <div>
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisEmpresa"
              @setCodigoArea="setCodigoAreaEmpresa"
              @setNumeroTelefono="setNumeroTelefonoEmpresa"
              @setExtension="setExtensionEmpresa"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="true"
              @keyup="listenChange"
            />
          </div>
        </v-col> -->
        <!-- <v-col cols="12" md="6">
          <div>
            <v-text-field
              v-model="sEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keyup="listenChange"
              @keypress="formatEmail"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sLocationAddress"
              type="text"
              label="Dirección comercial"
              placeholder="Dirección comercial"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span
                  >Dirección comercial <span class="color-red">*</span></span
                >
              </template>
            </v-textarea>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sLocationAddressDetail"
              type="text"
              label="Referencias"
              placeholder="Ej. Número de bodega, oficina, edificio, piso, departamento, etc."
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <!-- <template slot="label">
                <span
                  >Referencias <span class="color-red">*</span></span
                >
              </template> -->
            </v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sLocationCity"
              type="text"
              label="Ciudad"
              placeholder="Ciudad"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatCity"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Ciudad <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sLocationZIPCode"
              type="text"
              label="Código postal"
              placeholder="Código postal"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="5"
              persistent-placeholder
              @keypress="soloNumbers"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Código postal <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sCountry"
              label="País"
              placeholder="País"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aCountries"
              item-text="sName"
              item-value="sCountryId"
              @change="selectStates()"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>País <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sState"
              label="Estado"
              placeholder="Estado"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aStates"
              item-text="sName"
              item-value="sStateId"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Estado <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- #endregion Información de la empresa -->

    <!-- #region Información de facturación -->
    <div class="content-title-label mt-4 mb-4">Información de facturación</div>
    <v-container class="pl-0 pr-0" fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sRealName"
              type="text"
              label="Razón social"
              placeholder="Razón social"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keyup="listenChange"
              @keypress="soloLetters"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Razón social <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxId"
              type="text"
              label="RFC"
              placeholder="RFC"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="12"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>RFC <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico para facturación"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keyup="listenChange"
              @keypress="formatEmail"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sTaxLocationAddress"
              type="text"
              label="Dirección"
              placeholder="Dirección"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Dirección <span class="color-red">*</span></span>
              </template>
            </v-textarea>
          </div>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sTaxLocationAddressDetail"
              type="text"
              label="Referencias"
              placeholder="Ej. Número de bodega, oficina, edificio, piso, departamento, etc."
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
            </v-textarea>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxLocationCity"
              type="text"
              label="Ciudad"
              placeholder="Ciudad"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatCity"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Ciudad <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxLocationZIPCode"
              type="text"
              label="Código postal"
              placeholder="Código postal"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="5"
              persistent-placeholder
              @keypress="soloNumbers"
              @keyup="listenChange"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Código postal <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sTaxLocationCountry"
              label="País"
              placeholder="País"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aTaxCountries"
              item-text="sName"
              item-value="sCountryId"
              @change="selectTaxStates()"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>País <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sTaxLocationState"
              label="Estado"
              placeholder="Estado"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aTaxStates"
              item-text="sName"
              item-value="sStateId"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Estado <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- #endregion Información de facturación -->
    
    <!-- buttons -->
    <div v-if="getPermissionsActionsGlobal()" class="content-buttons">
      <v-btn
        @click="setDeleteDialog"
        class="button-secondary-delete poppins mr-3 w-165 mt-5"
        elevation="0"
      >
        <span> Eliminar</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!activeButtons"
        @click="discardChanges"
        class="button-secondary poppins"
        elevation="0"
      >
        Descartar cambios
      </v-btn>
      <!-- :disabled="!validationForm" -->
      <v-btn
        v-if="!activeButtons"
        @click="edit"
        :loading="bLoading"
        
        class="button-primary poppins"
        elevation="0"
      >
        Guardar cambios
      </v-btn>
    </div>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutDetailClientInformationGeneral",
  props: {
    items: Object,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      activeButtons: true,
      aCountries: [],
      aStates: [],
      aTaxCountries: [],
      aTaxStates: [],
      // Empresa
      sPublicName: "",
      sRealName: "",
      sCountryCallingCode: "",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",
      sEmail: "",
      sLocationCity: "",
      sLocationAddress: "",
      sLocationAddressDetail: "",
      sLocationZIPCode: "",
      sCountry: "",
      sState: "",
      // Facturacion
      sTaxId: "",
      sTaxEmail: "",
      sTaxLocationAddress: "",
      sTaxLocationAddressDetail: "",
      sTaxLocationCity: "",
      sTaxLocationZIPCode: "",
      sTaxLocationCountry: "",
      sTaxLocationState: "",
      // Representante
      sManagerCountryCallingCode: "",
      sManagerAreaCallingCode: "",
      sManagerPhoneExtension: "",
      sManagerPhoneNumber: "",
      sManagerJobTitle: "",
      sManagerName: "",
      sManagerLastname: "",
      sManagerEmail: "",
      itemsOriginal: {},
    };
  },
  beforeMount() {
    // Empresa
    this.itemsOriginal = this.items;
    this.sPublicName = this.items.sPublicName;
    this.sRealName = this.items.sRealName;
    let oGeneralInfo = this.items.oGeneralInfo;
    this.sCountryCallingCode = oGeneralInfo.oContactInfo.sCountryCallingCode;
    this.sAreaCallingCode = oGeneralInfo.oContactInfo.sAreaCallingCode;
    this.sPhoneNumber = oGeneralInfo.oContactInfo.sPhoneNumber;
    this.sPhoneExtension = oGeneralInfo.oContactInfo.sExtension;
    this.sEmail = oGeneralInfo.sEmail;
    this.sLocationCity = oGeneralInfo.oLocationInfo.sCityName;
    this.sLocationAddress = oGeneralInfo.oLocationInfo.sAddress;
    this.sLocationAddressDetail = oGeneralInfo.oLocationInfo.sOptionalAddress;
    this.sLocationZIPCode = oGeneralInfo.oLocationInfo.sLocationZIPCode;
    this.sCountry = oGeneralInfo.oLocationInfo.oCountry.sCountryId;
    this.sState = oGeneralInfo.oLocationInfo.oState.sStateId;
    // Facturacion
    let oTaxInfo = this.items.oTaxInfo;
    this.sTaxId = oTaxInfo.sTaxId;
    this.sTaxEmail = oTaxInfo.sEmail;
    this.sTaxLocationAddress = oTaxInfo.oLocationInfo.sAddress;
    this.sTaxLocationAddressDetail = oTaxInfo.oLocationInfo.sOptionalAddress;
    this.sTaxLocationCity = oTaxInfo.oLocationInfo.sCityName;
    this.sTaxLocationZIPCode = oTaxInfo.oLocationInfo.sLocationZIPCode;
    this.sTaxLocationCountry = oTaxInfo.oLocationInfo.oCountry.sCountryId;
    this.sTaxLocationState = oTaxInfo.oLocationInfo.oState.sStateId;
    // Representante
    let oManagerInfo = this.items.oManagerInfo;
    this.sManagerCountryCallingCode =
      oManagerInfo.oContactInfo.sCountryCallingCode;
    this.sManagerAreaCallingCode = oManagerInfo.oContactInfo.sAreaCallingCode;
    this.sManagerPhoneExtension = oManagerInfo.oContactInfo.sPhoneExtension;
    this.sManagerPhoneNumber = oManagerInfo.oContactInfo.sPhoneNumber;
    this.sManagerJobTitle = oManagerInfo.sJobTitle;
    this.sManagerName = oManagerInfo.sName;
    this.sManagerLastname = oManagerInfo.sLastname;
    this.sManagerEmail = oManagerInfo.sEmail;
    this.getCountries();
    this.getStates();
    this.getTaxCountries();
    this.getTaxStates();
  },
  methods: {
    edit: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                sPublicName: this.sPublicName,
                sRealName: this.sRealName,
                sPhoneNumber: this.sPhoneNumber,
                sEmail: this.sEmail,
                sLocationCity: this.sLocationCity,
                sLocationAddress: this.sLocationAddress,
                sLocationAddressDetail: this.sLocationAddressDetail,
                sLocationZIPCode: this.sLocationZIPCode,
                sStateId: this.sState,
                sCountryCallingCode: this.sCountryCallingCode,
                sAreaCallingCode: this.sAreaCallingCode,
                sPhoneExtension: this.sPhoneExtension,
                sTaxId: this.sTaxId,
                sTaxEmail: this.sTaxEmail,
                sTaxLocationAddress: this.sTaxLocationAddress,
                sTaxLocationAddressDetail: this.sTaxLocationAddressDetail,
                sTaxLocationCity: this.sTaxLocationCity,
                sTaxLocationZIPCode: this.sTaxLocationZIPCode,
                sTaxLocationStateId: this.sTaxLocationState,
                sManagerCountryCallingCode: this.sManagerCountryCallingCode,
                sManagerAreaCallingCode: this.sManagerAreaCallingCode,
                sManagerPhoneExtension: this.sManagerPhoneExtension,
                sManagerPhoneNumber: this.sManagerPhoneNumber,
                sManagerJobTitle: this.sManagerJobTitle,
                sManagerName: this.sManagerName,
                sManagerLastname: this.sManagerLastname,
                sManagerEmail: this.sManagerEmail,
                sManagerId: this.items.oManagerInfo.sUserId,
              };

            DB.put(
              `${URI}/enterprises/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;

                this.mixSuccess(response.data.message);
                this.returnClients();
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(error.response.data.message);
                // this.mixError(error.response.data.message,error.response.status);
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        });
    },
    listenChange: function () {
      let oGeneralInfo = this.itemsOriginal.oGeneralInfo;
      let oTaxInfo = this.itemsOriginal.oTaxInfo;
      let oManagerInfo = this.itemsOriginal.oManagerInfo;

      this.activeButtons =
        // Empresa
        this.sPublicName == this.itemsOriginal.sPublicName &&
        this.sRealName == this.itemsOriginal.sRealName &&
        this.sCountryCallingCode ==
          oGeneralInfo.oContactInfo.sCountryCallingCode &&
        this.sAreaCallingCode == oGeneralInfo.oContactInfo.sAreaCallingCode &&
        this.sPhoneNumber == oGeneralInfo.oContactInfo.sPhoneNumber &&
        this.sPhoneExtension == oGeneralInfo.oContactInfo.sExtension &&
        this.sEmail == oGeneralInfo.sEmail &&
        this.sLocationCity == oGeneralInfo.oLocationInfo.sCityName &&
        this.sLocationAddress == oGeneralInfo.oLocationInfo.sAddress &&
        this.sLocationAddressDetail ==
          oGeneralInfo.oLocationInfo.sOptionalAddress &&
        this.sLocationZIPCode == oGeneralInfo.oLocationInfo.sLocationZIPCode &&
        this.sCountry == oGeneralInfo.oLocationInfo.oCountry.sCountryId &&
        this.sState == oGeneralInfo.oLocationInfo.oState.sStateId &&
        // Facturacion

        this.sTaxId == oTaxInfo.sTaxId &&
        this.sTaxEmail == oTaxInfo.sEmail &&
        this.sTaxLocationAddress == oTaxInfo.oLocationInfo.sAddress &&
        this.sTaxLocationAddressDetail ==
          oTaxInfo.oLocationInfo.sOptionalAddress &&
        this.sTaxLocationCity == oTaxInfo.oLocationInfo.sCityName &&
        this.sTaxLocationZIPCode == oTaxInfo.oLocationInfo.sLocationZIPCode &&
        this.sTaxLocationCountry ==
          oTaxInfo.oLocationInfo.oCountry.sCountryId &&
        this.sTaxLocationState == oTaxInfo.oLocationInfo.oState.sStateId &&
        // Representante
        this.sManagerCountryCallingCode ==
          oManagerInfo.oContactInfo.sCountryCallingCode &&
        this.sManagerAreaCallingCode ==
          oManagerInfo.oContactInfo.sAreaCallingCode &&
        this.sManagerPhoneExtension ==
          oManagerInfo.oContactInfo.sPhoneExtension &&
        this.sManagerPhoneNumber == oManagerInfo.oContactInfo.sPhoneNumber &&
        this.sManagerJobTitle == oManagerInfo.sJobTitle &&
        this.sManagerName == oManagerInfo.sName &&
        this.sManagerLastname == oManagerInfo.sLastname &&
        this.sManagerEmail == oManagerInfo.sEmail;
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    discardChanges: function () {
      this.sPublicName = this.items.sPublicName;
      this.sRealName = this.items.sRealName;
      let oGeneralInfo = this.items.oGeneralInfo;
      this.sCountryCallingCode = oGeneralInfo.oContactInfo.sCountryCallingCode;
      this.sAreaCallingCode = oGeneralInfo.oContactInfo.sAreaCallingCode;
      this.sPhoneNumber = oGeneralInfo.oContactInfo.sPhoneNumber;
      this.sPhoneExtension = oGeneralInfo.oContactInfo.sPhoneExtension;
      this.sEmail = oGeneralInfo.sEmail;
      this.sLocationCity = oGeneralInfo.oLocationInfo.sCityName;
      this.sLocationAddress = oGeneralInfo.oLocationInfo.sAddress;
      this.sLocationAddressDetail = oGeneralInfo.oLocationInfo.sOptionalAddress;
      this.sLocationZIPCode = oGeneralInfo.oLocationInfo.sLocationZIPCode;
      this.sCountry = oGeneralInfo.oLocationInfo.oCountry.sCountryId;
      this.sState = oGeneralInfo.oLocationInfo.oState.sStateId;
      // Facturacion
      let oTaxInfo = this.items.oTaxInfo;
      this.sTaxId = oTaxInfo.sTaxId;
      this.sTaxEmail = oTaxInfo.sEmail;
      this.sTaxLocationAddress = oTaxInfo.oLocationInfo.sAddress;
      this.sTaxLocationAddressDetail = oTaxInfo.oLocationInfo.sOptionalAddress;
      this.sTaxLocationCity = oTaxInfo.oLocationInfo.sCityName;
      this.sTaxLocationZIPCode = oTaxInfo.oLocationInfo.sLocationZIPCode;
      this.sTaxLocationCountry = oTaxInfo.oLocationInfo.oCountry.sCountryId;
      this.sTaxLocationState = oTaxInfo.oLocationInfo.oState.sStateId;
      // Representante
      let oManagerInfo = this.items.oManagerInfo;
      this.sManagerCountryCallingCode =
        oManagerInfo.oContactInfo.sCountryCallingCode;
      this.sManagerAreaCallingCode = oManagerInfo.oContactInfo.sAreaCallingCode;
      this.sManagerPhoneExtension = oManagerInfo.oContactInfo.sPhoneExtension;
      this.sManagerPhoneNumber = oManagerInfo.oContactInfo.sPhoneNumber;
      this.sManagerJobTitle = oManagerInfo.sJobTitle;
      this.sManagerName = oManagerInfo.sName;
      this.sManagerLastname = oManagerInfo.sLastname;
      this.sManagerEmail = oManagerInfo.sEmail;
      this.activeButtons = true;
    },
    getCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aCountries = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectStates: function () {
      this.sState = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.sCountry,
        },
      })
        .then((response) => {
          this.aStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getTaxCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aTaxCountries = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectTaxStates: function () {
      this.sTaxLocationState = "";
      this.getTaxStates();
    },
    getTaxStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.sTaxLocationCountry,
        },
      })
        .then((response) => {
          this.aTaxStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setDeleteDialog() {
      let options = {
        active: true,
        id: this.$route.params.id,
        api: `/enterprises/${this.$route.params.id}`,
        title: "Eliminar cliente",
        description:
          "Eliminar un cliente es una acción irreversible </br>  ¿Desea continuar?",
        bReturnView: true,
      };
      this.$emit("setOptions", options);
    },
    // Reepresentante
    setCodigoPaisManager: function (e) {
      this.sManagerCountryCallingCode = e;
    },
    setCodigoAreaManager: function (e) {
      this.sManagerAreaCallingCode = e;
    },
    setNumeroTelefonoManager: function (e) {
      this.sManagerPhoneNumber = e;
    },
    setExtensionManager: function (e) {
      this.sManagerPhoneExtension = e;
    },
    // Empresa
    setCodigoPaisEmpresa: function (e) {
      this.sCountryCallingCode = e;
    },
    setCodigoAreaEmpresa: function (e) {
      this.sAreaCallingCode = e;
    },
    setNumeroTelefonoEmpresa: function (e) {
      this.sPhoneNumber = e;
    },
    setExtensionEmpresa: function (e) {
      this.sPhoneExtension = e;
    },
    returnClients: function () {
      this.aCountries = [];
      this.aStates = [];
      this.aTaxCountries = [];
      this.aTaxStates = [];
      // Empresa
      this.sPublicName = "";
      this.sRealName = "";
      this.sCountryCallingCode = "52";
      this.sAreaCallingCode = "";
      this.sPhoneNumber = "";
      this.sPhoneExtension = "";
      this.sEmail = "";
      this.sLocationCity = "";
      this.sLocationAddress = "";
      this.sLocationAddressDetail = "";
      this.sLocationZIPCode = "";
      this.sCountry = "";
      this.sState = "";
      // Facturacion
      this.sTaxId = "";
      this.sTaxEmail = "";
      this.sTaxLocationAddress = "";
      this.sTaxLocationAddressDetail = "";
      this.sTaxLocationCity = "";
      this.sTaxLocationZIPCode = "";
      this.sTaxLocationCountry = "";
      this.sTaxLocationState = "";
      // Representante
      this.sManagerCountryCallingCode = "52";
      this.sManagerAreaCallingCode = "";
      this.sManagerPhoneExtension = "";
      this.sManagerPhoneNumber = "";
      this.sManagerJobTitle = "";
      this.sManagerName = "";
      this.sManagerLastname = "";
      this.sManagerEmail = "";
      this.$router.push({
        name: "clients",
      });
    },
  },
  watch: {
    items: function () {},
    sCountry: function () {
      this.listenChange();
    },
    sState: function () {
      this.listenChange();
    },
    sTaxLocationCountry: function () {
      this.listenChange();
    },
    sTaxLocationState: function () {
      this.listenChange();
    },
    sCountryCallingCode: function () {
      this.listenChange();
    },
    sAreaCallingCode: function () {
      this.listenChange();
    },
    sPhoneNumber: function () {
      this.listenChange();
    },
    sPhoneExtension: function () {
      this.listenChange();
    },
    sManagerCountryCallingCode: function () {
      this.listenChange();
    },
    sManagerAreaCallingCode: function () {
      this.listenChange();
    },
    sManagerPhoneExtension: function () {
      this.listenChange();
    },
    sManagerPhoneNumber: function () {
      this.listenChange();
    },
    activeButtons() {
      this.$emit("getActiveBottom", this.activeButtons);
    },
  },
  computed: {
    validationForm: function () {
      console.table([
        this.sPublicName,
        this.sRealName,
        this.sManagerEmail,
        this.sLocationCity,
        this.sLocationAddress,
        this.sLocationZIPCode,
        this.sCountry,
        this.sState,
        // Facturacion
        this.sTaxId,
        this.sTaxEmail,
        this.sTaxLocationAddress,
        this.sTaxLocationCity,
        this.sTaxLocationZIPCode,
        this.sTaxLocationCountry,
        this.sTaxLocationState,
        // Representante
        this.sManagerCountryCallingCode,
        this.sManagerAreaCallingCode,
        this.sManagerPhoneNumber,
        this.sManagerName,
        this.sManagerLastname,
        this.sManagerEmail ,
      ]);
      return (
        this.sPublicName !== "" &&
        this.sRealName !== "" &&
        this.sEmail !== "" &&
        this.sLocationCity !== "" &&
        this.sLocationAddress !== "" &&
        this.sLocationZIPCode !== "" &&
        this.sCountry !== "" &&
        this.sState !== "" &&
        // Facturacion
        this.sTaxId !== "" &&
        this.sTaxEmail !== "" &&
        this.sTaxLocationAddress !== "" &&
        this.sTaxLocationCity !== "" &&
        this.sTaxLocationZIPCode !== "" &&
        this.sTaxLocationCountry !== "" &&
        this.sTaxLocationState !== "" &&
        // Representante
        this.sManagerCountryCallingCode !== "" &&
        this.sManagerAreaCallingCode !== "" &&
        this.sManagerPhoneNumber !== "" &&
        this.sManagerName !== "" &&
        this.sManagerLastname !== "" &&
        this.sManagerEmail !== ""
      );
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-primary,
.button-secondary {
  width: 150px;
}

.button-primary {
  margin-left: 10px;
}

.content-label-txt-title {
  background: #d2e4fe 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 2px 15px;
  width: fit-content;
}

.border-top-line {
  border-top: 1px solid #d0dbf2;
}

.border-bottom-line {
  border-bottom: 1px solid #d0dbf2;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .button-primary,
  .button-secondary {
    width: 100%;
  }

  .button-secondary {
    margin-top: 10px;
  }

  .button-primary {
    margin-left: 0px !important;
    margin-top: 15px;
    width: 100%;
  }
}
</style>
