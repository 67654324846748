<template>
  <div>
    <div v-if="bLocation">
      <MglMap :accessToken="mapboxAccessToken" :mapStyle.sync="mapStyle" :center="coordinates" :minZoom="minZoom"
        :maxZoom="maxZoom">
        <MglMarker :coordinates="coordinates" color="#142456" />
      </MglMap>
      <!-- <GmapMap
      :center="center"
      :zoom="12"
      :options="mapOptions"
      style="width: 100%; height: 600px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap> -->
    </div>
    <div v-else class="content-empty-location">
      <p v-if="bLoadingLocation" class="text-empty-location">
        Obteniendo información...
      </p>
      <p v-if="bEmptyLocation" class="text-empty-location">
        Información de localización no disponible
      </p>
    </div>
  </div>
</template>

<script>
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  name: "layoutTabsLocalizacion",
  props: {
    tab: Number,
  },
  components: {
    MglMap,
    MglMarker,
  },
  data() {
    return {
      bEmptyLocation: false,
      bLoadingLocation: true,
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        // maxZoom: 10,
        // minZoom: 3,
      },
      oFixedInfo: {},
      oLocation: {},
      bLocation: false,
      mapboxAccessToken:
        "pk.eyJ1IjoiYWFyaXpwZW8iLCJhIjoiY2wydDVxeXF0MDE3ZTNjczA0am9vNG1hZiJ9.79cpuMO-s82AMRiV3Oxgng",
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      coordinates: [],
      maxZoom: 15,
      minZoom: 6,
    };
  },
  activated() {
    if (this.tab == 4) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.bLoadingLocation = true;
      this.bEmptyLocation = false;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URL_KITE_LOCAL}/${this.$route.params.id}/location`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              this.oFixedInfo = response.data.results.oFixedInfo;
              this.$emit("setFixedInfo", this.oFixedInfo);
              this.oLocation = response.data.results.oLocation;
              if (
                this.oLocation.iLatitude !== "N/A" ||
                this.oLocation.iLongitude !== "N/A"
              ) {
                this.bLocation = true;
                this.coordinates = [
                  this.oLocation.iLongitude,
                  this.oLocation.iLatitude,
                ];
                this.setMap(this.oLocation.iLongitude, this.oLocation.iLatitude);
              } else {
                this.bLocation = false;
                this.bLoadingLocation = false;
                this.bEmptyLocation = true;
              }
              this.markerWarehouse = {
                lat:
                  this.oLocation.iLatitude !== "N/A" ? this.oLocation.iLatitude : 0,
                lng:
                  this.oLocation.iLongitude !== "N/A"
                    ? this.oLocation.iLongitude
                    : 0,
              };

              this.addMarker();
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    setPlace(place) {
      this.currentPlace = place;

      this.addMarker();
    },
    addMarker() {
      this.markers = [];
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      } else {
        this.markers.push({ position: this.markerWarehouse });
        this.center = this.markerWarehouse;
      }
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      if (this.tab == 4) {
        this.getData();
      }
    },
    refresh() {
      this.getData();
    },
  },
};
</script>
<style scoped>
.content-empty-location {
  display: flex;
  justify-content: center;
  height: 100%;
}

.text-empty-location {
  text-align: center !important;
  margin-top: 20%;
}
</style>